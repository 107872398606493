import React from 'react'

const Offer = () => {
  return (
    <>
      OFFER
    </>
  )
}

export default Offer